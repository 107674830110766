@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: url('../static/poppins-regular.woff2') format('woff2'),
    url('../static/poppins-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../static/poppins-extrabold.woff2') format('woff2'),
    url('../static/poppins-extrabold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

:root {
  --viggo-logo-from: #E4E4E7;
  --viggo-logo-to: white;
}

.rhv-logo:hover,
.rhv-logo:focus {
  box-shadow: 0 0 3rem 3rem rgba(255, 255, 255, .5);
}

.viggo-logo {
  background: var(--viggo-logo-from);
  background: -webkit-linear-gradient(45deg, var(--viggo-logo-from), var(--viggo-logo-to));
  background: linear-gradient(45deg, var(--viggo-logo-from), var(--viggo-logo-to));
  background-clip: text;
  color: transparent;
}
